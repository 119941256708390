import styled from 'styled-components';

export const ItemDateEl = styled.div`
  background: #e4e7ed;
  text-align: center;
  padding: 0.375rem;
  border-radius: 4px;
  height: 60px;
  width: 50px;
  box-sizing: border-box;
  color: hsla(0, 0%, 0%, 0.7) !important;

  .day {
    font-size: 18px;
    line-height: 18px;
    display: block;
  }
  .month {
    font-size: 12px;
    display: block;
  }
  .time {
    font-size: 11px;
  }
`;

export const ItemInfosEl = styled.div`
  flex: 1;
  padding: 0 1.5rem;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const ItemProduitsEl = styled.ul`
  list-style: none;
  margin: 0.75rem 0 0 0;
  padding: 0;

  li {
    display: flex;
    flex-direction: row;
    img {
      width: 40px;
      height: 40px;
      margin-right: 0.75rem;
    }

    span.name {
      font-size: 14px;
      display: block;
      margin: 0;
      padding: 0;
      font-weight: normal;
    }

    span.variant {
      font-size: 13px;
    }

    span.price {
      font-size: 14px;
    }

    .variant + .price {
      margin-left: 0.375rem;
    }

    &:not(:first-child) {
      margin-top: 0.375rem;
    }
  }
`;

export const MessageEl = styled.li`
  cursor: pointer;
  display: flex;
  border: 1px solid #e4e7ed;
  padding: 0.375rem;
  background: #fbfbfc;
  margin-bottom: 0.375rem;
`;

export const MessagesEl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ItemInfosTextSC = styled.p`
  margin: 0.375rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 95%;
`;
