import { DemandeData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../../../../../components/MessageByContacts/Item';
import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import List from '../../../../../CMS/containers/Boutique/components/new/List';
import params from '../../params.json';

const DemandeList: FC<BoutiqueProps> = ({ boutique }) => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix="/boutique/demandes/"
        model={
          new DemandeData({
            boutique,
            params,
            tabs: [
              {
                label: 'En attente',
                pathname: '/boutique/demandes/',
                wheres: {
                  hasResponse: false,
                },
              },
            ],
          })
        }
        title={t('demandes')}
      />
    </CMSView>
  );
};

export default requireBoutique(DemandeList);
