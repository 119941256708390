import { BoutiqueData, ProduitData } from '@innedit/innedit';
import React, { FC, memo, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ItemProduitsEl } from './styles';

interface ItemProduitsProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  produitIds: string[];
}
const ItemProduits: FC<ItemProduitsProps> = ({ boutique, produitIds }) => {
  const [produits, setProduits] = useState<
    firebase.default.firestore.QueryDocumentSnapshot[]
  >([]);

  useEffect(() => {
    const produit = new ProduitData({ boutique });
    const unsub = produit.watchByIds(produitIds, querySnapshot => {
      setProduits(querySnapshot.docs);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, produitIds]);

  return (
    <ItemProduitsEl>
      {produits &&
        produits.map(produit => {
          const photo =
            produit.get('photos') && produit.get('photos').length > 0
              ? produit.get('photos')[0]
              : undefined;

          const price = produit.get('price');

          return (
            <li key={produit.id || uuidv4()}>
              {photo && (
                <img
                  alt={produit.get('name')}
                  src={`${BoutiqueData.getDomainImagesURL(
                    boutique,
                  )}/h_80,w_80/${photo}`}
                />
              )}
              <div>
                <span className="name">{produit.get('name')}</span>
                <span className="price">{price}€</span>
              </div>
            </li>
          );
        })}
    </ItemProduitsEl>
  );
};

export default memo(ItemProduits);
