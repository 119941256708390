import { PageProps } from 'gatsby';
import React, { FC } from 'react';

// import DemandeForm from '../../core/sysext/Boutique/containers/Demande/components/Form';
import DemandeList from '../../core/sysext/Boutique/containers/Demande/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const DemandesPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        // <DemandeForm docId={star} {...props} />
        <div>Il faut le faire</div>
      ) : (
        <DemandeList {...props} />
      )}
    </Backoffice>
  );
};

export default DemandesPage;
